<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Terminal Models">

                <b-button variant="black2" @click="addTerminalModelModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="terminalModels" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="openEdit(terminalModelsRaw[props.row.index])" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button variant="danger" @click="removeTerminalModel(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <feather-icon size="38" v-if="props.row.type_id === 0" icon="HardDriveIcon"/>
                        <feather-icon size="38" v-else icon="MonitorIcon"/>
                    </div>
                    <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.firmware_upgrades_enabled" variant="success">{{ props.row.firmware_upgrades_enabled_text }}</b-badge>
                        <b-badge v-else variant="light-danger">No</b-badge>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
                
            </b-card>
        </b-overlay>

        <b-modal title="Add terminal model" v-model="addTerminalModelModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Type</label>
                    <v-select
                        v-model="addObject.type"
                        :reduce="type => type.id"
                        label="name"
                        :options="[{id: 0, name: 'STB'}, {id: 1, name: 'TV'}]"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Codename</label>
                    <b-form-input v-model="addObject.codename"/>
                </b-form-group>

                <b-form-group>
                    <label>Manufacturer Name</label>
                    <b-form-input v-model="addObject.manufacturer_name"/>
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addTerminalModelModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addTerminalModel">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal title="Edit terminal model" v-model="editTerminalModelModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Type</label>
                    <v-select
                        v-model="editObject.type"
                        :reduce="type => type.id"
                        label="name"
                        :options="[{id: 0, name: 'STB'}, {id: 1, name: 'TV'}]"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="editObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Codename</label>
                    <b-form-input v-model="editObject.codename"/>
                </b-form-group>

                <b-form-group>
                    <label>Manufacturer Name</label>
                    <b-form-input v-model="editObject.manufacturer_name"/>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox v-model="editObject.firmware_upgrades_enabled">Firmware upgrades enabled</b-form-checkbox>
                </b-form-group>

                <b-form-group v-if="editObject.firmware_upgrades_enabled">
                    <label>Firmware package</label>
                    <v-select
                        v-model="editObject.current_firmware_package_id"
                        :reduce="firmwarePackage => firmwarePackage.id"
                        label="version"
                        :options="firmwarePackages.filter((firmwarePackage) => firmwarePackage.terminal_model_id === editObject.id )"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editTerminalModelModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="saveTerminalModel">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>

    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay, BFormCheckbox, BBadge} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'

    export default {
        components: {
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            BFormCheckbox,
            BBadge,
            vSelect
        },
        data() {
            return {
                dataLoaded: false,
                terminalModelsRaw: [],

                columns: [
                    {
                        label: 'Type',
                        displayType: 2,
                        field: 'type',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Codename',
                        displayType: 0,
                        field: 'codename',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Manufacturer Name',
                        displayType: 0,
                        field: 'manufacturer_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Firmware upgrades',
                        displayType: 3,
                        field: 'firmware_upgrades_enabled_text',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                firmwarePackages: [],

                addTerminalModelModalActive: false,
                addObject: {
                    type: 0,
                    name: '',
                    codename: '',
                    manufacturer_name: '',
                    firmware_upgrades_enabled: false,
                    current_firmware_package_id: this.nullObjectId
                },

                editTerminalModelModalActive: false,
                editObject: {
                    type: 0,
                    name: '',
                    codename: '',
                    manufacturer_name: '',
                    firmware_upgrades_enabled: false,
                    current_firmware_package_id: this.nullObjectId
                }
            }
        },
        methods: {
            openEdit(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.editTerminalModelModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/terminal_model')
                loadPromise.then(function(response) {
                    thisIns.terminalModelsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const firmwarePackagesLoadPromise = this.$http.get('/api/management/v1/firmware_package')
                firmwarePackagesLoadPromise.then(function(response) {
                    thisIns.firmwarePackages = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, firmwarePackagesLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addTerminalModel() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/terminal_model', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Terminal model added')
                    thisIns.addTerminalModelModalActive = false
                    thisIns.addObject = {
                        type: 0,
                        name: '',
                        codename: '',
                        manufacturer_name: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            saveTerminalModel() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/terminal_model/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Terminal model saved')
                    thisIns.editTerminalModelModalActive = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeTerminalModel(id) {
                const thisIns = this
                const savePromise = this.$http.delete(`/api/management/v1/terminal_model/${  id}`)
                savePromise.then(function() {
                    thisIns.$printSuccess('Terminal model removed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        computed: {
            terminalModels() {
                return this.terminalModelsRaw.map((terminalModel, index) => {
                    return {
                        index,
                        id: terminalModel.id,
                        type_id: terminalModel.type,
                        type: (terminalModel.type === 0) ? 'STB' : 'TV',
                        name: terminalModel.name,
                        codename: terminalModel.codename,
                        manufacturer_name: terminalModel.manufacturer_name,
                        firmware_upgrades_enabled: terminalModel.firmware_upgrades_enabled,
                        firmware_upgrades_enabled_text: (terminalModel.firmware_upgrades_enabled) ? `Yes - Version: ${terminalModel.current_firmware_package_version}` : 'No'
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>